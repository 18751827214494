import type {
  Module,
  PiletCustomApi,
  PiralPlugin,
  ProductQuoteId,
} from 'piral-core';
import * as actions from './actions';
import initFlowState from './initFlowState';
import { ModuleType, ResourceConfig } from './types';

export { ModuleToFlowProgressBoundary } from './maps';
export {
  createCanNavigate,
  findNextPageLabel,
  getRefinedRoute,
} from './navigationManagement';
export * from './selectors';
export type { Module, ProductQuoteId };
export { ModuleType };

declare let CONFIG: ResourceConfig;

const DEF_HEAD_TITLE = 'American Family Quote';
const DEF_LOAD_MSG = 'Loading ...';

export default (): PiralPlugin<PiletCustomApi> => (context) => {
  context.defineActions(actions);
  context.dispatch((state) => ({
    ...state,
    digitalsales: {
      analyticData: {},
      displayCallCenterBox: false,
      callCenterBoxStartPosition: '',
      lockCallCenterBox: false,
      displayCreditBasedScoringLink: false,
      displayHeaderFooter: true,
      displayProgressStepper: false,
      displaySaveForLater: false,
      displayNeedHelp: true,
      yourAgentData: undefined,
      fullPageDisplay: false,
      pageGradientDisplay: false,
      featureMeta: {
        userInfo: { packageId: '', productCodes: [] },
        featureResourceKey: '',
      },
      lockProgressStepper: false,
      headerTitle: DEF_HEAD_TITLE,
      loadingState: [],
      pageEnablerMap: {},
      pageItemMetaMap: {},
      pageExternalComponentMap: {},
      productQuoteIds: [],
      resourceData: {},
      productCodes: [],
      ...initFlowState,
    },
  }));

  return () => ({
    digitalsales: {
      addAnalyticData(data) {
        context.addAnalyticData(data);
      },
      getAnalyticData() {
        return context.getAnalyticData();
      },
      configureCallCenterBox(display, startPosition, lock) {
        context.configureCallCenterBox(display, startPosition, lock);
      },
      configureModule(type, pages, productCodes) {
        context.configureModule(type, pages, productCodes);
      },
      configureProgressStepper(display, lock) {
        context.configureProgressStepper(display, lock);
      },
      configureFullPageDisplay(fullPageDisplay) {
        context.configureFullPageDisplay(fullPageDisplay);
      },
      configureGradientPageDisplay(gradientPageDisplay) {
        context.configureGradientPageDisplay(gradientPageDisplay);
      },
      displayCreditBasedScoringLink(indicator) {
        context.displayCreditBasedScoringLink(indicator);
      },
      displaySaveForLater(indicator) {
        context.displaySaveForLater(indicator);
      },
      displayNeedHelp(indicator) {
        context.displayNeedHelp(indicator);
      },
      setYourAgentData(agent) {
        context.setYourAgentData(agent);
      },
      getYourAgentData() {
        return context.getYourAgentData();
      },
      displayHeaderFooter(indicator) {
        context.displayHeaderFooter(indicator);
      },
      enablePage(route, indicator) {
        context.enablePage(route, indicator);
      },
      getPageEnablerMap() {
        return context.getPageEnablerMap();
      },
      registerPageEnabler(route, enabler, productCode) {
        context.registerPageEnabler(route, enabler, productCode);
      },
      getDocItemMetaMap(productCode, pathName) {
        return context.getDocItemMetaMap(productCode, pathName);
      },
      registerDocItemMetaMap(route, productCode, docItemMetaMap) {
        context.registerDocItemMetaMap(route, productCode, docItemMetaMap);
      },
      getExternalComponents(productCodes, sectionName, pathName) {
        return context.getExternalComponents(
          productCodes,
          sectionName,
          pathName,
        );
      },
      hasExternalComponents(route, productCodes) {
        return context.hasExternalComponents(route, productCodes);
      },
      registerExternalComponents(route, productCode, sectionComponentMap) {
        context.registerExternalComponents(
          route,
          productCode,
          sectionComponentMap,
        );
      },
      setFeaturesResult(userInfo, key) {
        context.configureAppFeatures(userInfo, key);
      },
      getNextPageLabel(location) {
        return context.getNextPageLabel(location);
      },
      getPreviousPageLabel(location) {
        return context.getPreviousPageLabel(location);
      },
      getResourceConfig() {
        return CONFIG;
      },
      getResourceData(key, defaultData) {
        return context.getResourceData(key, defaultData);
      },
      setResourceData(key, data) {
        context.setResourceData(key, data);
      },
      navigateToNextModule(navigate, location) {
        context.navigateToNextModule(navigate, location);
      },
      navigateToPreviousModule(navigate, location) {
        context.navigateToPreviousModule(navigate, location);
      },
      navigateToNextPage(navigate, location) {
        context.navigateToNextPage(navigate, location);
      },
      navigateToPreviousPage(navigate, location) {
        context.navigateToPreviousPage(navigate, location);
      },
      navigateToPage(route, packageId, otherParams, navigate, location) {
        context.navigateToPage(
          route,
          navigate,
          location,
          true,
          otherParams,
          packageId,
        );
      },
      navigateToProductModule(productCode, packageId, navigate, location) {
        context.navigateToProductModule(
          productCode,
          packageId,
          navigate,
          location,
        );
      },
      navigateToQuoteModule(navigate, location) {
        context.navigateToQuoteModule(navigate, location);
      },
      navigateToQuoteFlow(packageId, otherParams, navigate, location) {
        return context.navigateToQuoteFlow(
          packageId,
          otherParams,
          navigate,
          location,
        );
      },
      setFlowState(flowProgress) {
        context.setFlowState(flowProgress);
      },
      setHeaderTitle(title) {
        context.setHeaderTitle(title || DEF_HEAD_TITLE);
      },
      setLoadingState(processName, isLoading, message) {
        context.setLoadingState(
          processName,
          isLoading,
          message || DEF_LOAD_MSG,
        );
      },
      setProductQuoteIds(productQuoteIds) {
        context.setProductQuoteIds(productQuoteIds);
      },
      verifyOrNavigateToEligiblePage(navigate, location) {
        return context.verifyOrNavigateToEligiblePage(navigate, location);
      },
    },
  });
};
