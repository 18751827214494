import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  GlobalStateContext,
  Module,
  Page,
  QuoteFlow,
  withKey,
} from 'piral-core';
import { Location, NavigateFunction } from 'react-router';
import { ModuleToFlow, ProductToModule } from './maps';
import {
  createCanNavigate,
  createCanResume,
  findNextPageLabel,
  getRefinedRoute,
} from './navigationManagement';
import { ModuleType, ProductCode } from './types';

const navigateToModule = (
  ctx: GlobalStateContext,
  navigateCommand: (
    modules: Module[],
    currentModuleIndex: number,
    currentModulePageIndex: number,
  ) => void,
  location: Location,
) => {
  const digitalsales = ctx.readState((state) => state.digitalsales);
  const { quoteFlow, bindFlow } = digitalsales;
  const modules = [...Object.values(quoteFlow), ...Object.values(bindFlow)];

  const currentRoute = location.pathname;
  const currentModuleIndex = modules.findIndex((module) =>
    module.routes.includes(currentRoute),
  );
  const currentModulePageIndex =
    currentModuleIndex === -1
      ? -1
      : modules[currentModuleIndex].pages.findIndex(
          (page) => page.route === currentRoute,
        );

  navigateCommand(modules, currentModuleIndex, currentModulePageIndex);
};

export const navigateToPage = (
  _: GlobalStateContext,
  route: string,
  navigate: NavigateFunction,
  location: Location,
  fromPrecedingPage?: boolean,
  otherParams?: URLSearchParams,
  packageId?: string,
): void => {
  const refinedLocation = getRefinedRoute(
    route,
    packageId,
    otherParams,
    fromPrecedingPage,
  )(location);
  navigate(`${refinedLocation.pathname}${refinedLocation.search}`, {
    replace: true,
    state: refinedLocation.state,
  });
};

const navigateToModulePage = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
  module?: Module,
  fromPrecedingPage?: boolean,
  otherParams?: URLSearchParams,
  packageId?: string,
  lastPage: boolean = false,
) => {
  if (module) {
    const { routes } = module;
    if (!isEmpty(routes)) {
      const route = routes[lastPage ? routes.length - 1 : 0];
      navigateToPage(
        ctx,
        route,
        navigate,
        location,
        fromPrecedingPage,
        otherParams,
        packageId,
      );

      // Update current flow setting
      ctx.dispatch((state) => ({
        ...state,
        digitalsales: withKey(
          state.digitalsales,
          'currentFlow',
          ModuleToFlow[module.type],
        ),
      }));

      return route === location.pathname;
    }
  }
  return true;
};

const navigateToPreviousX = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
  pageFilter: (pageCandidate: Page, currentPage: Page) => boolean,
  lastPage: boolean,
): void =>
  navigateToModule(
    ctx,
    (modules, currentModuleIndex, currentModulePageIndex) => {
      let page;

      if (currentModulePageIndex > 0) {
        const currentModule = modules[currentModuleIndex];
        const currentPages = currentModule.pages;
        const currentPage = currentPages[currentModulePageIndex];
        page = currentPages
          .slice(0, currentModulePageIndex)
          .reverse()
          .find((pg) => pageFilter(pg, currentPage));
      }

      if (page) {
        navigateToPage(ctx, page.route, navigate, location, false);
      } else if (currentModuleIndex > 0) {
        const previousModule = modules
          .slice(0, currentModuleIndex)
          .reverse()
          .find((module) => module.active);
        navigateToModulePage(
          ctx,
          navigate,
          location,
          previousModule,
          false,
          undefined,
          undefined,
          lastPage,
        );
      }
    },
    location,
  );

export const navigateToPreviousModule = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
): void =>
  navigateToPreviousX(
    ctx,
    navigate,
    location,
    (pageCandidate, currentPage) =>
      pageCandidate.enabled &&
      !isEqual(pageCandidate.productCodes, currentPage.productCodes),
    false,
  );

export const navigateToPreviousPage = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
): void =>
  navigateToPreviousX(
    ctx,
    navigate,
    location,
    (pageCandidate) => pageCandidate.enabled,
    true,
  );

const navigateToNextX = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
  pageFilter: (pageCandidate: Page, currentPage: Page) => boolean,
): void =>
  navigateToModule(
    ctx,
    (modules, currentModuleIndex, currentModulePageIndex) => {
      let page;

      const currentModule = modules[currentModuleIndex];
      const currentPages = currentModule.pages;
      if (currentModulePageIndex < currentPages.length - 1) {
        const currentPage = currentPages[currentModulePageIndex];
        page = currentPages
          .slice(currentModulePageIndex + 1, currentPages.length)
          .find((pg) => pageFilter(pg, currentPage));
      }

      if (page) {
        navigateToPage(ctx, page.route, navigate, location, true);
      } else if (currentModuleIndex < modules.length - 1) {
        const nextModule = modules
          .slice(currentModuleIndex + 1, modules.length)
          .find((module) => module.active);
        navigateToModulePage(ctx, navigate, location, nextModule, true);
      }
    },
    location,
  );

export const navigateToNextPage = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
): void =>
  navigateToNextX(
    ctx,
    navigate,
    location,
    (pageCandidate) => pageCandidate.enabled,
  );

export const navigateToNextModule = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
): void =>
  navigateToNextX(
    ctx,
    navigate,
    location,
    (pageCandidate, currentPage) =>
      pageCandidate.enabled &&
      !isEqual(pageCandidate.productCodes, currentPage.productCodes),
  );

export const verifyOrNavigateToEligiblePage = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
): boolean => {
  let result = true;
  navigateToModule(
    ctx,
    (modules, currentModuleIndex, currentModulePageIndex) => {
      if (currentModulePageIndex === -1) {
        return;
      }

      const flowProgressStatus = ctx.readState(
        (state) => state.digitalsales.currentFlowProgressStatus,
      );
      const currentModule = modules[currentModuleIndex];
      const currentPages = currentModule.pages;
      const currentPage = currentPages[currentModulePageIndex];
      if (createCanNavigate(flowProgressStatus)(currentPage)) {
        return;
      }

      const canResume = createCanResume(flowProgressStatus);
      let nextPage: Page | undefined;
      modules.some((module) => {
        if (module.active) {
          nextPage = module.pages.find(
            (page) => page.enabled && canResume(page, module),
          );
          return !!nextPage;
        }
        return false;
      });

      if (nextPage) {
        navigateToPage(ctx, nextPage.route, navigate, location, true);
      }
      result = false;
    },
    location,
  );
  return result;
};

export const navigateToProductModule = (
  ctx: GlobalStateContext,
  productCode: ProductCode,
  packageId: string | undefined,
  navigate: NavigateFunction,
  location: Location,
): void =>
  navigateToModule(
    ctx,
    (modules) => {
      const productModule = modules.find(
        (module) => module.type === ProductToModule[productCode],
      );
      navigateToModulePage(
        ctx,
        navigate,
        location,
        productModule,
        true,
        undefined,
        packageId,
      );
    },
    location,
  );

export const navigateToQuoteModule = (
  ctx: GlobalStateContext,
  navigate: NavigateFunction,
  location: Location,
): void =>
  navigateToModule(
    ctx,
    (modules) => {
      const quoteModule = modules.find(
        (module) => module.type === ModuleType.Quote,
      );
      navigateToModulePage(ctx, navigate, location, quoteModule, true);
    },
    location,
  );

export const navigateToQuoteFlow = (
  ctx: GlobalStateContext,
  packageId: string | undefined,
  otherParams: URLSearchParams | undefined,
  navigate: NavigateFunction,
  location: Location,
): boolean => {
  const quoteFlow = ctx.readState(
    (state) => state.digitalsales.quoteFlow,
  ) as QuoteFlow;
  const { pages, type } = Object.values(quoteFlow)[0];

  if (!isEmpty(pages)) {
    const { route } = pages[0];
    navigateToPage(
      ctx,
      route,
      navigate,
      location,
      true,
      otherParams,
      packageId,
    );

    // Update current flow setting
    ctx.dispatch((state) => ({
      ...state,
      digitalsales: withKey(
        state.digitalsales,
        'currentFlow',
        ModuleToFlow[type],
      ),
    }));

    return route === location.pathname;
  }

  return true;
};

export const getPreviousPageLabel = (
  ctx: GlobalStateContext,
  location: Location,
): string => {
  const quoteFlow = ctx.readState((state) => state.digitalsales.quoteFlow);
  const bindFlow = ctx.readState((state) => state.digitalsales.bindFlow);
  const modules = [...Object.values(quoteFlow), ...Object.values(bindFlow)];
  const activeModules: Module[] = modules.filter(
    (module: Module) => module.active,
  );
  return findNextPageLabel(activeModules, location, true);
};

export const getNextPageLabel = (
  ctx: GlobalStateContext,
  location: Location,
): string => {
  const quoteFlow = ctx.readState((state) => state.digitalsales.quoteFlow);
  const bindFlow = ctx.readState((state) => state.digitalsales.bindFlow);
  const modules = [...Object.values(quoteFlow), ...Object.values(bindFlow)];
  const activeModules: Module[] = modules.filter(
    (module: Module) => module.active,
  );
  return findNextPageLabel(activeModules, location);
};
