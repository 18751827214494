import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import {
  DocItemMetaMap,
  GlobalStateContext,
  ProductExternalComponent,
  SectionExternalComponentMap,
  withKey,
} from 'piral-core';
import { productCodeSeqList } from './maps';
import type { ProductCode } from './types';

export const registerDocItemMetaMap = (
  ctx: GlobalStateContext,
  route: string,
  productCode: ProductCode,
  docItemMetaMap: DocItemMetaMap,
): void =>
  ctx.dispatch((state) => {
    const pageItemMetaMap = cloneDeep(state.digitalsales.pageItemMetaMap);

    let productDocItemMetaMap = pageItemMetaMap[route];
    if (!productDocItemMetaMap) {
      productDocItemMetaMap = {};
      pageItemMetaMap[route] = productDocItemMetaMap;
    }

    const currentDocItemMetaMap = productDocItemMetaMap[productCode];
    if (!currentDocItemMetaMap) {
      productDocItemMetaMap[productCode] = docItemMetaMap;
    }

    return {
      ...state,
      digitalsales: withKey(
        state.digitalsales,
        'pageItemMetaMap',
        pageItemMetaMap,
      ),
    };
  });
export const getDocItemMetaMap = (
  ctx: GlobalStateContext,
  productCode: ProductCode,
  pathName: string,
): DocItemMetaMap => {
  const pageItemMetaMap = ctx.readState(
    (state) => state.digitalsales.pageItemMetaMap,
  );
  const productDocItemMetaMap = pageItemMetaMap[pathName] || {};
  return productDocItemMetaMap[productCode];
};

export const registerExternalComponents = (
  ctx: GlobalStateContext,
  route: string,
  productCode: ProductCode,
  sectionComponentMap: SectionExternalComponentMap,
): void =>
  ctx.dispatch((state) => {
    const pageComponentMap = cloneDeep(
      state.digitalsales.pageExternalComponentMap,
    );

    let sectionComponentsMap = pageComponentMap[route];
    if (!sectionComponentsMap) {
      sectionComponentsMap = {};
      pageComponentMap[route] = sectionComponentsMap;
    }

    Object.entries(sectionComponentMap).forEach(([sectionName, component]) => {
      const productComponents = sectionComponentsMap[sectionName];
      if (productComponents) {
        const finalProductComponents = uniqBy(
          [...productComponents, { ...component, productCode }],
          'productCode',
        );
        finalProductComponents.sort(
          (compA, compB) =>
            productCodeSeqList.indexOf(compA.productCode) -
            productCodeSeqList.indexOf(compB.productCode),
        );
        sectionComponentsMap[sectionName] = finalProductComponents;
      } else {
        sectionComponentsMap[sectionName] = [{ ...component, productCode }];
      }
    });

    return {
      ...state,
      digitalsales: withKey(
        state.digitalsales,
        'pageExternalComponentMap',
        pageComponentMap,
      ),
    };
  });
export const getExternalComponents = (
  ctx: GlobalStateContext,
  productCodes: ProductCode[],
  sectionName: string | undefined,
  pathName: string,
): ProductExternalComponent[] => {
  const pageComponentMap = ctx.readState(
    (state) => state.digitalsales.pageExternalComponentMap,
  );
  const sectionComponentsMap = pageComponentMap[pathName] || {};
  let components;
  if (sectionName) {
    components = sectionComponentsMap[sectionName] || [];
  } else {
    components = Object.values(sectionComponentsMap).reduce(
      (primeComps, comps) => primeComps.concat(comps),
      [],
    );
  }
  return components.filter((comp) => productCodes.includes(comp.productCode));
};
export const hasExternalComponents = (
  ctx: GlobalStateContext,
  route: string,
  productCodes: ProductCode[],
): boolean => {
  const pageComponentMap = ctx.readState(
    (state) => state.digitalsales.pageExternalComponentMap,
  );
  const sectionComponentsMap = pageComponentMap[route] || {};
  return Object.values(sectionComponentsMap).some((comps) =>
    comps.some((comp) => productCodes.includes(comp.productCode)),
  );
};
